
import { Component, Prop, Vue } from 'vue-property-decorator'
import BISList from '@/interfaces/bis_list'

@Component
export default class SyncCurrentGear extends Vue {
  @Prop()
  bisLists!: BISList[]

  @Prop()
  save!: (syncIds: string[]) => void

  @Prop()
  verb!: string

  check(e: MouseEvent, bisId: number): void {
    // First, ignore this function if the target was the checkbox
    if (e === null || (e.target as HTMLElement).tagName === 'INPUT') return
    // Find the checkbox that has the id as its data-target
    const checkbox = this.$el.querySelector(`input[data-target="${bisId}"]`) as HTMLInputElement | null
    if (checkbox === null) return
    checkbox.checked = !checkbox.checked
  }

  get checkboxes(): HTMLInputElement[] {
    return this.$refs.checkbox as HTMLInputElement[]
  }

  sync(): void {
    // Get the ids of the checked boxes and pass them back
    const toSync: string[] = []
    this.checkboxes.forEach((checkbox: HTMLInputElement) => {
      if (checkbox.checked) toSync.push(checkbox.dataset.target as string)
    })
    this.save(toSync)
    this.$emit('close')
  }
}

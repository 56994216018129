
import { Component, Prop, Vue } from 'vue-property-decorator'
import BISList from '@/interfaces/bis_list'

@Component
export default class LoadCurrentGear extends Vue {
  @Prop()
  bisLists!: BISList[]

  @Prop()
  loadBIS!: (list: BISList) => void

  select(list: BISList): void {
    this.loadBIS(list)
    this.$emit('close')
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import GearDropdown from '@/components/gear_dropdown.vue'
import BISListModify from '@/dataclasses/bis_list_modify'
import { BISListErrors } from '@/interfaces/responses'
import Gear from '@/interfaces/gear'

@Component({
  components: {
    GearDropdown,
  },
})
export default class BIS extends Vue {
  @Prop()
  bisList!: BISListModify

  @Prop()
  displayOffhand!: boolean

  @Prop()
  errors!: BISListErrors

  @Prop()
  maxIl!: number

  @Prop()
  minIl!: number

  // Filtered array of gear for accessories
  get accessories(): Gear[] {
    return this.gear.filter((item: Gear) => item.has_accessories)
  }

  // Filtered array of gear for armour
  get armour(): Gear[] {
    return this.gear.filter((item: Gear) => item.has_armour)
  }

  // Get gear from store
  get gear(): Gear[] {
    return this.$store.state.gear
  }

  // Filtered array of gear for weapons
  get weapons(): Gear[] {
    return this.gear.filter((item: Gear) => item.has_weapon)
  }
}


import { Component } from 'vue-property-decorator'
import GearDropdown from '@/components/gear_dropdown.vue'
import BIS from '@/components/bis_list/bis.vue'

@Component({
  components: {
    GearDropdown,
  },
})
export default class Current extends BIS {}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { XIVGearSheetSelection } from '@/interfaces/imports'

@Component
export default class XIVGearSheetSetSelection extends Vue {
  @Prop()
  doImport!: (setNum: number) => Promise<void>

  @Prop()
  sheets!: XIVGearSheetSelection[]

  importSet(setNum: number): void {
    this.doImport(setNum)
    this.$emit('close')
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import BISListModify from '@/dataclasses/bis_list_modify'
import { BISListErrors } from '@/interfaces/responses'
import Job from '@/interfaces/job'

@Component
export default class Details extends Vue {
  baseImgUrl = '/job_icons/'

  displayOffhand = true

  @Prop()
  bisList!: BISListModify

  @Prop()
  charIsProxy!: boolean

  @Prop()
  errors!: BISListErrors

  get jobs(): Job[] {
    return this.$store.state.jobs
  }

  // Conversion getters for job related refs
  get jobIcon(): HTMLImageElement {
    return this.$refs.jobIcon as HTMLImageElement
  }

  get jobPicker(): HTMLSelectElement {
    return this.$refs.jobPicker as HTMLSelectElement
  }

  changeJob(): void {
    const selectedJob = (this.jobPicker.options[this.jobPicker.selectedIndex]).value as string

    // Handle the flag for the offhand
    this.$emit('job-change', selectedJob)
  }
}
